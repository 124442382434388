import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages, dateTimeFormats } from './utils'

Vue.use(VueI18n)

export const getLocale = () => {
  const storeLanguage = JSON.parse(window.localStorage.getItem('auth.currentLanguage') || '{}')

  if (storeLanguage && storeLanguage.code) {
    document.documentElement.lang = storeLanguage.code
    return storeLanguage.code
  }

  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)

  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale
      return locale
    }
  }

  // Default language is polish
  return 'pl'
}

const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: 'pl',
  messages,
  dateTimeFormats,
})

export default i18n
