<template>
  <b-button :id="`translation-btn${input}${id}`" variant="outline-secondary" size="sm" class="ts-button" :disabled="disabled" v-b-modal.modal="`${input}${id}`">
    <i class="ri-translate"></i>
    <b-modal :id="`${input}${id}`" :title="title" no-close-on-backdrop>
      <b-container>
        <div class="row">
          <div class="col-sm-12">
            <b-container>
              <div class="row p-1" v-for="i in lang.length" :key="i">
                <div class="col-sm-4">
                  <p>{{ lang[i - 1].name }}</p>
                </div>
                <div class="col-sm-8">
                  <b-form-input v-model="lang[i - 1].value" size="sm"></b-form-input>
                </div>
              </div>
            </b-container>
          </div>
        </div>
      </b-container>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="outline-danger" @click="cancel"> {{ $t('commands.cancel') }} </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="
            save()
            ok()
          "
        >
          {{ $t('commands.ok') }}
        </b-button>
      </template>
    </b-modal>
  </b-button>
</template>
  
<script>
import Languages from '../../dto/Languages.json'

export default {
  name: 'FieldsTranslation',

  props: {
    value: {
      type: Object,
      default: null,
    },
    input: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      title: this.$tc('commands.inputTranslations'),
      allLanguages: Languages,
      lang: [],
    }
  },

  watch: {
    value() {
      this.updateLang()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.updateLang()
    },

    updateLang() {
      this.lang = []

      if (this.value === null || !this.value[this.input]) {
        for (let i = 0; i < this.allLanguages.length; i++) {
          this.lang.push({ code: this.allLanguages[i].code, name: this.allLanguages[i].name, value: '' })
        }
      } else {
        for (let i = 0; i < this.allLanguages.length; i++) {
          this.lang.push({
            code: this.allLanguages[i].code,
            name: this.allLanguages[i].name,
            value: this.value[this.input][this.allLanguages[i].code] ? this.value[this.input][this.allLanguages[i].code] : '',
          })
        }
      }
    },

    async save() {
      const newLang = { [this.input]: {} }

      if (this.value !== null) {
        for (const key of Object.keys(this.value)) {
          if (key !== this.input) {
            newLang[key] = this.value[key]
          }
        }
      }

      for (const langItem of this.lang) {
        newLang[this.input][langItem.code] = langItem.value
      }

      this.$emit('input', newLang)
      this.lang = []
    },
  },
}
</script>

<style scoped>
.input-group-append .btn-sm {
  padding: 0 0.5rem !important;
  border-color: #adb5bd;
}
</style>