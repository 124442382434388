import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = getBaseUrl()

function getBaseUrl() {
  if (process.env.VUE_APP_BASE_INTERNAL_API_URL && process.env.VUE_APP_BASE_INTERNAL_API_URL === window.location.origin) {
    return `${process.env.VUE_APP_BASE_INTERNAL_API_URL}/api`
  } else {
    return process.env.VUE_APP_BASE_API_URL ? process.env.VUE_APP_BASE_API_URL : ''
  }
}
