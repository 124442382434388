import _ from 'lodash'
import { ActionContext } from 'vuex'
import Vue from 'vue'

interface IFilesManager {
  navMode: string
  currFolder: object | null
  currItem: object | null
  showDetails: boolean
  viewMode: string
  sortBy: string
  sortDesc: boolean
  searchStr: string
  expandedFolders: Array<any>
}

const initState: IFilesManager = {
  navMode: 'myFiles',
  currFolder: null,
  currItem: null,
  showDetails: false,
  viewMode: 'list',
  sortBy: 'name',
  sortDesc: false,
  searchStr: '',
  expandedFolders: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setNavMode(state: IFilesManager, payload: string) {
    state.navMode = payload
  },
  setCurrentFolder(state: IFilesManager, payload: Array<any>) {
    state.currFolder = payload
  },

  setCurrentItem(state: IFilesManager, payload: Array<any>) {
    state.currItem = payload
  },

  setShowDetails(state: IFilesManager, payload: boolean) {
    state.showDetails = payload
  },

  setViewMode(state: IFilesManager, payload: string) {
    state.viewMode = payload
  },

  setSort(state: IFilesManager, payload: any) {
    state.sortBy = payload.sortBy
    state.sortDesc = payload.sortDesc
  },

  setSearchStr(state: IFilesManager, payload: string) {
    state.searchStr = payload
  },

  setExpandedFolders(state: IFilesManager, payload: Array<any>) {
    state.expandedFolders = payload
  },

  setProperty(state: IFilesManager, payload: any) {
    Vue.set(payload.object, payload.property, payload.value)
  },

  setProperties(state: IFilesManager, payload: any) {
    Object.keys(payload.props).forEach((key) => {
      Vue.set(payload.object, key, payload.props[key])
    })
  },

  deleteItem(state: IFilesManager, payload: any) {
    payload.items.splice(payload.index, 1)
  },

  addItem(state: IFilesManager, payload: any) {
    if (payload.index !== undefined) {
      payload.items.splice(payload.index, 0, payload.item)
    } else {
      payload.items.push(payload.item)
    }
  },

  resetState(state: IFilesManager) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  resetState(context: ActionContext<IFilesManager, any>) {
    context.commit('resetState')
  },

  uploadFiles(context: ActionContext<IFilesManager, any>, payload: any) {},
}
