import { render, staticRenderFns } from "./object-field.vue?vue&type=template&id=6612b093&scoped=true"
import script from "./object-field.vue?vue&type=script&lang=js"
export * from "./object-field.vue?vue&type=script&lang=js"
import style0 from "./object-field.vue?vue&type=style&index=0&id=6612b093&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6612b093",
  null
  
)

export default component.exports