export function defineFieldType(type: string): string {
  let itemType = type.toLowerCase()

  if (itemType === 'string' || itemType === 'uuid') {
    itemType = 'text'
  } else if (itemType === 'decimal' || itemType === 'integer') {
    itemType = 'number'
  }

  return itemType
}

export function defineDataType(type: string): string {
  const itemType = type.toLowerCase()
  return itemType
}
