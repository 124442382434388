<template>
  <div>
    <div class="preloader">
      <div id="status">
        <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner" variant="primary"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLoading',

  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
}
</script>