import axios from 'axios'
import { showMessageBox as cuShowMessageBox, showQuestion as cuShowQuestion } from '@/utils/commonUse.js'

export const actions = {
  async runSyncData({ commit }: any, filterParams: any) {
    const response = await axios.get(`/get_from_1c`, filterParams)

    if (response) {
      return response
    } else throw new Error('No Content')
  },

  async sendTo1C({ commit }: any, filterParams: any) {
    const response = await axios.get(`/send_to_1c`, filterParams).catch((error) => {
      throw error
    })
    if (response) {
      return response
    } else throw new Error('No Content')
  },

  async sendToImpulse({ commit }: any, filterParams: any) {
    const response = await axios.get(`/send_to_impulse`).catch((error) => {
      throw error
    })
    if (response) {
      return response
    } else throw new Error('No Content')
  },

  async getFromImpulse({ commit }: any, filterParams: any) {
    const response = await axios.get(`/get_from_impulse`).catch((error) => {
      throw error
    })
    if (response) {
      return response
    } else throw new Error('No Content')
  },

  showMessageBox({ commit }: any, upload: any) {
    cuShowMessageBox(upload.view, upload.text, upload.fontsize, upload.isRedText, '', upload.showSeconds)
  },
}
