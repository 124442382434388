<template>
  <draggable v-model="fields" v-bind="{ group: { name: 'view-items', pull: 'clone', put: false }, sort: false, ghostClass: 'ghost' }">
    <div v-for="(field, idx) in fields" :key="idx">
      <div v-if="(field.fieldType === 'object' || field.isTablePart) && field.childs.length > 0">
        <div class="row object-field" :class="{ 'item-active': currField === field }" @dblclick="onSelectField(field)" @click="onClickField(field)">
          <div class="col">
            <div>
              <a v-b-toggle="field.name" href="#">
                <i :class="{ 'ri-checkbox-indeterminate-line': field.expanded, 'ri-add-box-line': !field.expanded }" class="mr-1"></i>
              </a>
              <i :class="{ 'ri-table-2': field.isTablePart, 'ri-subtract-line': !field.isTablePart }" class="mr-1"></i>
              <span>{{ field.label }}</span>
            </div>
          </div>
        </div>
        <b-collapse :id="field.name" v-model="field.expanded" class="ml-3">
          <ObjectField
            :child-list="true"
            :currField="currField"
            :fields="field.childs"
            @active-field="onClickField"
            @select-field="onSelectField"
          ></ObjectField>
        </b-collapse>
      </div>
      <div v-else>
        <div class="row object-field" :class="{ 'item-active': currField === field }" @dblclick="onSelectField(field)" @click="onClickField(field)">
          <div class="col">
            <div>
              <a href="#"><i class="ri-checkbox-indeterminate-line mr-1"></i></a>
              <i :class="{ 'ri-table-2': field.isTablePart, 'ri-subtract-line': !field.isTablePart }" class="mr-1"></i>
              <span>{{ field.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'ObjectField',

  components: { draggable },

  props: {
    fields: {
      type: [],
      required: true,
    },
    childList: {
      type: Boolean,
      default: false,
    },
    currField: {
      type: Object,
    },
  },

  methods: {
    onClickField(field) {
      this.$emit('active-field', field)
    },

    onSelectField(field) {
      this.$emit('select-field', field)
    },
  },
}
</script>

<style scoped>
.child-list {
  margin-left: 1rem;
}

.object-field {
  padding: 0.3rem;
  border: dotted #adb5bd 1px;
  background-color: #fefefe;
  border-radius: 0.25rem;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 3px;
}

.item-active {
  background-color: #dee2e6;
}

.object-field i {
  margin-top: 0.2rem;
}
</style>