<template>
  <div class="d-flex flex-column flex-column-reverse flex-md-row align-items-end">
    <TagsView />

    <div class="px-2 px-md-0 d-flex justify-content-between justify-content-md-end topbar-right-menu">
      <div class="d-flex align-items-center">
        <a role="button" class="text-white menu-btn d-inline-block d-md-none mr-1" @click="toggleMenu">
          <i class="ri-menu-3-line" v-if="!this.isToggle"></i>
          <i class="ri-close-line" v-if="this.isToggle"></i>
        </a>
        <a href="/" class="logo d-block d-md-none">
          <img :src="logoUrl" alt />
        </a>
      </div>

      <ul class="topbar-right-account-menu align-items-center p-0">
        <li class="nav-item dropdown notification-list d-none d-md-flex">
          <a class="nav-link dropdown-toggle arrow-none" href="#" id="not-dropdown-btn" role="button" data-toggle="dropdown" aria-expanded="false">
            <i class="ri-notification-3-line icon"></i>
            <span v-if="notificationItems.length > 0" class="noti-icon-badge"></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg" aria-labelledby="not-dropdown-btn">
            <li>
              <a href="#" class="dropdown-item noti-title">
                <h5 class="m-0">
                  {{ $t('navbar.notifications') }}
                </h5>
              </a>
            </li>
            <simplebar style="max-height: 230px">
              <a v-for="item in notificationItems" :key="item.id" class="dropdown-item notify-item" href="javascript: void(0);" @click="openNotification(item)">
                <div v-if="item.icon" class="notify-icon" :class="`bg-${item.iconColor}`">
                  <i :class="`${item.icon}`"></i>
                </div>
                <p class="notify-details">{{ item.title }}</p>
                <p class="text-muted mb-0 user-msg">
                  <small>{{ item.description }}</small>
                </p>
              </a>
            </simplebar>
            <li>
              <a href="#" class="dropdown-item text-center text-primary notify-item notify-all" @click="goToRecievedMessages">
                {{ $t('navbar.checkAll') }}
                <i class="fi-arrow-right"></i>
              </a>
            </li>
          </ul>
        </li>

        <li class="d-none d-md-flex">
          <ScreenFull />
        </li>
        <li>
          <a class="nav-link toggle-right" @click="toggleRightSidebar">
            <i class="ri-time-line icon toggle-right"></i>
          </a>
        </li>

        <li class="nav-item dropdown notification-list">
          <a
            class="nav-link nav-user dropdown-toggle arrow-none mr-0"
            href="#"
            id="user-dropdown-btn"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="account-user-avatar">
              <img src="@/assets/images/users/white.png" alt="user-image" />
            </span>
            <span class="account-user-name">{{ user ? user.name : '' }}</span>
          </a>

          <ul class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown" aria-labelledby="user-dropdown-btn">
            <div class="dropdown-header noti-title">
              <h5 class="text-overflow m-0">{{ $t('navbar.hello') }}</h5>
            </div>
            <a href="javascript:void(0);" class="dropdown-item notify-item" @click="openProfile">
              <i class="ri-user-2-line mr-1"></i>
              <span>{{ $t('navbar.profile') }}</span>
            </a>
            <hr />
            <a href="javascript:void(0);" class="dropdown-item notify-item" @click="logout">
              <i class="ri-logout-box-r-line mr-1"></i>
              <span>{{ $t('navbar.logOut') }}</span>
            </a>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Simplebar from 'simplebar-vue'
import ScreenFull from './screen-full/index'
import Languages from '../dto/Languages.json'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import _ from 'lodash'
import TagsView from './tags-view'
import resetStates from '@/store/service/resetStates'
import { authComputed } from '@/store/helpers'
import { resetRouter } from '@/router'

export default {
  name: 'PageTopbar',

  components: { Simplebar, ScreenFull, TagsView },

  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      languages: _.cloneDeep(Languages),
      isToggle: false,
    }
  },

  computed: {
    ...mapGetters({
      currentLanguage: 'auth/currentLanguage',
      notificationItems: 'notifications/notificationList',
    }),
    ...authComputed,

    theme() {
      return process.env.VUE_APP_THEME || 'default'
    },

    logoUrl() {
      return require(`@/assets/images/logo/${this.theme}/logo-black.png`)
    },
  },

  created: async function () {
    this.isToggle = false
    document.body.classList.remove('toggle-side-menu')
  },

  async mounted() {
    await this.$store.dispatch('notifications/getNotifications', { params: { read: false } })
  },

  methods: {
    ...mapMutations({
      setCurrentLanguage: 'auth/setCurrentLanguage',
    }),

    ...mapActions({
      delAllViews: 'tagsViews/delAllViews',
    }),

    goToRecievedMessages() {},

    async openNotification(item) {
      if (item.objectName === 'customer_request' && item.objectId !== null) {
        const response = await this.$store.dispatch('customerRequests/findByPk', {
          params: {
            id: item.objectId,
          },
        })

        if (response.status === 200) {
          await this.$store.dispatch('notifications/updateNotification', {
            params: { id: item.id },
            updateData: {
              read: true,
            },
          })

          this.$router.push({ name: 'CustomerRequest' })
        }
      }
    },

    toggleMenu() {
      // this.$parent.toggleMenu()
      document.body.classList.toggle('toggle-side-menu')
      this.isToggle = !this.isToggle
    },

    async toggleRightSidebar() {
      const objectsList = await this.$store.dispatch('objectsTimeTracking/getCurrentObjects')
      document.body.classList.toggle('right-bar-enabled')
    },

    async logout() {
      await this.$store.dispatch('auth/logOut')
      this.delAllViews()
      resetStates()
      resetRouter()
      this.$router.push({ path: '/' })
    },

    async openProfile() {
      const itemId = this.currentUser.id

      const dataObject = await this.$store.dispatch('users/findByPk', {
        params: {
          id: itemId,
        },
      })
      if (dataObject) {
        this.$router.push({ name: 'user-profile', params: { id: itemId } })
      }
    },

    changeLanguage(lang) {
      this.setCurrentLanguage(lang)
      this.$i18n.locale = lang.code
      if (this.$route.path !== '/login') {
        location.reload()
      }
    },
  },
}
</script>

<style scoped>
</style>
