<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
  </div>
</template>

<script lang="ts">
import appConfig from '@/app.config.json'
import { Component, Vue } from 'vue-property-decorator'
import DefaultLayout from '@/layouts/default.vue'
import MainLayout from '@/layouts/main.vue'

@Component<App>({
  components: { DefaultLayout, MainLayout },

  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title: any) {
      title = typeof title === 'function' ? title().title : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
})
export default class App extends Vue {
  get layout() {
    return this.$route.meta?.layout ? this.$route.meta?.layout : 'main-layout'
  }
}
</script>

<style lang="scss">
@import '@/design/app.scss';
</style>
