import moment from 'moment'
import _ from 'lodash'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import axios from 'axios'
// import { findAll, findByPk, createItem, updateItem, changeDeletionMark, addNewItem, deleteItem, resetState } from '../service/actions'

//import BasicObject from '@/dto/EmailAccount.json'
const apiUrl = 'database_translation'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = { ...commonMutations }

export const actions = {

  getFileForTranslation( context: ActionContext<IState, any>, payload: any) {

    return axios
    .get(`/${apiUrl}/file`, payload)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error(error)
      return error
    })

  },
 
  loadTranslationFromFile( context: ActionContext<IState, any>, payload: any) {

    return axios
    .post(`/${apiUrl}`, payload)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error(error)
      return error
    })
  
  },
  
}

export const getters = { ...commonGetters }
