export default {
  async setPredefinedStatus(predefinedName, $store) {
    const response = await $store.dispatch('customerRequestStatuses/findPredefined', {
      params: { name: predefinedName },
    })

    let res = {}
    if (response.status === 200) {
      res = { statusId: response.data.id }
      return res
    } else return res
  },

  async checkCustomerRequestStatus(predefinedName, $store, args) {
    const response = await $store.dispatch('customerRequestStatuses/findPredefined', {
      params: {
        name: predefinedName,
      },
    })

    if (response.status === 200) {
      if (args.customerRequest.statusId === response.data.id) {
        return true
      } else return false
    } else return false
  },

  async getCustomerRequestStatus(predefinedName, $store) {
    const response = await $store.dispatch('customerRequestStatuses/findPredefined', {
      params: {
        name: predefinedName,
      },
    })
    let res
    if (response.status === 200) {
      res = response.data
    }
    return res
  },
}
