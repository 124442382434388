export const state = {
  layoutType: getSavedState('app.layoutType') || 'vertical',
  layoutWidth: getSavedState('app.layoutWidth') || 'fluid',
  leftSidebarTheme: getSavedState('app.leftSidebarTheme') || 'dark',
  leftSidebarType: getSavedState('app.leftSidebarType') || 'fixed',
}

export const getters = {
  layoutType(state: any) {
    return state.layoutType
  },
  layoutWidth(state: any) {
    return state.layoutWidth
  },
  leftSidebarTheme(state: any) {
    return state.leftSidebarTheme
  },
  leftSidebarType(state: any) {
    return state.leftSidebarType
  },
}

export const mutations = {
  CHANGE_LAYOUT(state: any, layoutType: any) {
    state.layoutType = layoutType
  },
  CHANGE_LEFT_SIDEBAR_THEME(state: any, leftSidebarTheme: any) {
    state.leftSidebarTheme = leftSidebarTheme
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state: any, leftSidebarType: any) {
    state.leftSidebarType = leftSidebarType
  },
  CHANGE_LAYOUT_WIDTH(state: any, layoutWidth: any) {
    state.layoutWidth = layoutWidth
  },
}

export const actions = {
  changeLayoutType({ commit }: any, { layoutType }: any) {
    commit('CHANGE_LAYOUT', layoutType)
    saveState('app.layoutType', layoutType)
  },

  changeLeftSidebarTheme({ commit }: any, { leftSidebarTheme }: any) {
    commit('CHANGE_LEFT_SIDEBAR_THEME', leftSidebarTheme)
    saveState('app.leftSidebarTheme', leftSidebarTheme)
  },

  changeLeftSidebarType({ commit }: any, { leftSidebarType }: any) {
    commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
    saveState('app.leftSidebarType', leftSidebarType)
  },

  changeLayoutWidth({ commit }: any, { layoutWidth }: any) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
    saveState('app.layoutWidth', layoutWidth)
  },
}

// ===
// Private helpers
// ===

function getSavedState(key: any) {
  const state = window.localStorage.getItem(key)
  return state ? JSON.parse(state) : null
}

function saveState(key: any, state: any) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
