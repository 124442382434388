import store from '@/store'
import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state: any) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const getSavedState = (key: any) => {
  const state = window.localStorage.getItem(key)
  return state ? JSON.parse(state) : null
}

export const saveState = (key: any, state: any) => {
  window.localStorage.setItem(key, JSON.stringify(state))
}
