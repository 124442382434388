<template>
  <div>
    <Loading v-if="loading" />

    <div v-show="!loading" class="row align-items-stretch min-vh-100">
      <div class="col-6 bg-default d-none d-md-flex justify-content-center align-items-center" :class="{ 'bg-container': theme !== 'default' }">
        <img :src="bgAuth" />
      </div>
      <div class="col p-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/loading'

export default {
  name: 'DefaultLayout',

  data() {
    return {
      loading: true,
    }
  },

  components: {
    Loading,
  },

  computed: {
    theme() {
      return process.env.VUE_APP_THEME || 'default'
    },
    bgAuth() {
      return require(`@/assets/images/bg/${this.theme}.png`)
    },
  },

  mounted() {
    this.loading = true
    this.$nextTick(function () {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    })
  },
}
</script>

<style lang="scss" scoped>
.bg-container {
  overflow: hidden;
  padding-right: 0;
  background: none !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
