<template>
  <b-form-group horizontal :label-cols="filter.label.length > 12 ? 3 : 2" :label="filter.label" class="mb-1">
    <div class="row">
      <div :class="`col-${operator !== 'filled' && operator !== 'notFilled' ? '3' : '12'}`" v-if="operators.length > 0">
        <div class="d-flex">
          <b-form-checkbox v-model="use" class="mr-1 mt-1"> </b-form-checkbox>
          <b-form-select v-model="operator" :options="operators" size="sm"></b-form-select>
        </div>
      </div>
      <div class="col" v-if="operator !== 'filled' && operator !== 'notFilled'">
        <div class="d-flex">
          <b-form-checkbox v-if="operators.length === 0" v-model="use" class="mr-1 mt-1"> </b-form-checkbox>
          <f-select v-if="filter.fieldType === 'object'" v-model="value" select-btn :multiple="multiple" :value-type="filter.ref"></f-select>
          <b-form-select v-else-if="filter.fieldType === 'enum'" v-model="value" :options="filter.options" :multiple="multiple" size="sm">
            <template v-slot:first>
              <b-form-select-option :value="null">-- {{ $t('common.notSelected') }} --</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select v-else-if="filter.fieldType === 'boolean'" v-model="value" :options="booleanOptions" size="sm">
            <template v-slot:first>
              <b-form-select-option :value="null">-- {{ $t('common.notSelected') }} --</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-input v-else v-model="value" :type="filter.fieldType" size="sm" />
        </div>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    filter: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      booleanOptions: [
        { value: true, text: this.$tc('common.yes') },
        { value: false, text: this.$tc('common.no') },
      ],
      operators: [],
      definedOperators: {
        object: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        enum: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        text: ['eq', 'ne', 'iLike', 'notILike', 'filled', 'notFilled'],
        number: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
        date: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
      },
      multiple: false,
    }
  },

  computed: {
    use: {
      get() {
        return this.filter.use
      },
      set(value) {
        const newFilter = _.cloneDeep(this.filter)
        newFilter.use = value
        this.$emit('change-filter', newFilter)
      },
    },

    value: {
      get() {
        return this.filter.value
      },
      set(value) {
        const newFilter = _.cloneDeep(this.filter)
        newFilter.value = value
        this.$emit('change-filter', newFilter)
      },
    },
    operator: {
      get() {
        return this.filter.operator
      },
      set(value) {
        let filterValue = null
        let multiple

        if (value === 'inList' || value === 'notInList') {
          filterValue = []
          multiple = true
        } else {
          multiple = false
        }

        const newFilter = _.cloneDeep(this.filter)
        newFilter.operator = value

        if (this.multiple !== multiple) {
          this.multiple = multiple
          newFilter.value = filterValue
        }

        this.$emit('change-filter', newFilter)
      },
    },
  },

  mounted() {
    this.initOperators()
    this.multiple = this.operator === 'inList' || this.operator === 'notInList'
  },

  methods: {
    initOperators() {
      let operators = []

      if (this.filter.fieldType !== 'boolean') {
        operators = this.definedOperators[this.filter.fieldType]
      }

      this.operators = operators.map((el) => {
        return { value: el, text: this.$tc(`operators['${el}']`) }
      })
    },
  },
}
</script>

<style>
</style>