<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">2022 - {{ new Date().getFullYear() }} © INTELEKTSOFT - SynergyCore</div>
        <div class="col-md-4">
          <span class="float-right">Version [client: {{ $store.state.app?.info?.clientVersion }}, server: {{ $store.state.app?.info?.serverVersion }}]</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
}
</script>
