<template>
  <b-modal
    id="select-view"
    size="xl"
    dialog-class="pl-0 pl-md-5"
    :title="`${$t('common.selectPossition')}`"
    no-close-on-backdrop
    @cancel="handleCancel"
    @close="handelClose"
  >
    <div class="row">
      <div class="col">
        <div class="row mb-2">
          <div class="col-2">
            <b-button v-if="addBtn" id="f-select-add" variant="outline-secondary" size="sm" @click="addDetailView">
              <i class="ri-add-line"></i> {{ this.$t('commands.add') }}
            </b-button>
            <b-button v-if="filters.quick.length > 0" id="q-filter-btn" variant="outline-secondary" size="sm" @click="showQuickFilters = !showQuickFilters">
              <i class="ri-filter-line"></i>
            </b-button>
          </div>
          <div class="col">
            <b-form-input
              id="search-item"
              v-model="searchStr"
              type="search"
              debounce="500"
              size="sm"
              :placeholder="`${$t('common.search')}...`"
              @update="onChangeFilter"
            >
            </b-form-input>
          </div>
        </div>
        <div class="row">
          <div class="col" v-for="(field, index) in advancedFilterFields" :key="'filter_' + index">
            <b-form-group :label="field.label" :label-for="`item-${field.key}`">
              <b-form-textarea
                v-if="field.type === 'textarea'"
                :id="`item-${field.key}`"
                :value="advancedFilters[field.key]"
                :name="`item-${field.key}`"
                rows="3"
                max-rows="6"
                size="sm"
                @input="(val) => updateVariant(field.key, val)"
              />
              <b-form-checkbox
                v-else-if="field.type === 'switch'"
                :id="`item-${field.key}`"
                :value="advancedFilters[field.key]"
                :name="`item-${field.key}`"
                switch
                size="sm"
                @input="(val) => updateVariant(field.key, val)"
              >
              </b-form-checkbox>
              <date-picker
                v-else-if="field.type === 'date' || field.type === 'time' || field.type === 'datetime'"
                :id="`item-${field.key}`"
                :value="advancedFilters[field.key]"
                :name="`item-${field.key}`"
                :type="field.type"
                size="sm"
                :lang="$store.state.auth.currentLanguage.code"
                @update="(val) => updateAdvancedFilter(field.key, val)"
              />
              <b-form-input
                v-else
                :id="`item-${field.key}`"
                :value="advancedFilters[field.key]"
                :type="field.type"
                :name="`item-${field.key}`"
                size="sm"
                debounce="50"
                @update="(val) => updateAdvancedFilter(field.key, val)"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-collapse id="q-filters-list" v-model="showQuickFilters">
              <div class="row row-cols-lg-1 row-cols-1">
                <div class="col" v-for="(qFilter, idx) of filters.quick.filter((el) => el.hidden !== true)" :key="idx">
                  <quick-filter v-if="!qFilter.hidden" :filter="qFilter" @change-filter="onChangeQuickFilter"></quick-filter>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <b-table
          ref="selectTable"
          no-border-collapse
          :items="items"
          :fields="fields"
          responsive="sm"
          selectable
          select-mode="single"
          no-local-sorting
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sticky-header="400px"
          lazy
          :per-page="perPage"
          :current-page="1"
          small
          @row-selected="onRowSelected"
          @sort-changed="onSortingChanged"
        >
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" class="my-0" @change="onChangePage"></b-pagination>
      </div>
    </div>

    <template v-slot:modal-footer="{ cancel }">
      <b-button size="sm" variant="outline-danger" @click="cancel"> {{ $t('commands.cancel') }} </b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { uuid } from 'vue-uuid'
import moment from 'moment'
import QuickFilter from '@/components/common/quick-filter'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    QuickFilter,
  },

  props: {
    valueType: {
      type: String,
      require: true,
    },
    label: {
      type: String,
      default: 'name',
    },
    addBtn: {
      type: Boolean,
      default: false,
    },
    detailView: {
      type: String,
      default: null,
    },
    filter: {
      type: Object,
      default: null,
    },
    advancedFilter: {
      type: Boolean,
      default: false,
    },
    advancedFilterStatic: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      fields: [{ key: this.label, label: this.$t('table.name') }],
      items: [],
      searchStr: '',
      filters: {
        quick: [],
      },
      showQuickFilters: false,
      itemId: null,
      sortBy: null,
      sortDesc: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      advancedFilterFields: [],
      advancedFilters: {},
      viewSettings: null,
      userViewSettings: null,
      objectData: {},
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      currentLanguage: 'auth/currentLanguage',
    }),
  },

  async mounted() {
    this.$bvModal.show('select-view')
  },

  async created() {
    await this.initialize()
  },

  methods: {
    ...mapMutations({
      addObjectView(commit, payload) {
        return commit(this.valueType + '/addObjectView', payload)
      },
      setListViewProperty(commit, payload) {
        return commit(this.valueType + '/setListViewProperty', payload)
      },
      setFilter(commit, payload) {
        return commit(this.valueType + '/setFilters', payload)
      },
      setSort(commit, payload) {
        return commit(this.valueType + '/setSort', payload)
      },
    }),
    async initialize() {
      // this.busy = true
      await this.initViewSettings()
      await this.initFields()
      await this.updateList()
      // this.busy = false
    },

    async initViewSettings() {
      this.viewSettings = null
      await this.$store.dispatch('appObjects/findAll', { noCommit: true, params: { filter: { name: this.valueType } } }).then(async (response) => {
        if (response.data && response.data.length > 0) {
          this.objectData = response.data[0]
          await this.$store
            .dispatch('viewSettings/findAll', { noCommit: true, params: { filter: { appObjectId: response.data[0].id, isDefault: true, viewType: 'select' } } })
            .then(async (responseSet) => {
              if (responseSet.data && responseSet.data.length > 0) {
                this.viewSettings = responseSet.data[0]

                if (this.viewSettings.module && this.viewSettings.module?.length > 0) {
                  this.initViewModule()
                }
                await this.$store
                  .dispatch('userViewSettings/findAll', { noCommit: true, params: { filter: { viewId: this.viewSettings.id, userId: this.currentUser.id } } })
                  .then((responseUserSet) => {
                    if (responseUserSet.data && responseUserSet.data.length > 0) {
                      this.userViewSettings = responseUserSet.data[0]
                    } else {
                      this.userViewSettings = null
                    }
                  })
              }
            })
        }
      })
    },

    initViewModule() {
      try {
        const moduleData = eval(`(${this.viewSettings.module})`)

        if (moduleData) {
          if (moduleData.data) {
            for (const varKey in moduleData.data) {
              this[varKey] = moduleData.data[varKey]
            }
          }

          if (moduleData.methods) {
            for (const method in moduleData.methods) {
              this[method] = moduleData.methods[method]
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    async initFields() {
      try {
        const viewSettings = this.userViewSettings ? this.userViewSettings : this.viewSettings ? this.viewSettings : null
        if (viewSettings) {
          if (viewSettings.items) {
            const listFields = viewSettings?.items.filter((el) => {
              return el.visible === true
            })

            const fields = listFields.map((el) => {
              let label = el.label ? el.label : this.$tc(`table.${el.name}`)

              if (el.lang?.label && this.currentLanguage) {
                const currLocal = el.lang.label[this.currentLanguage.code]
                if (currLocal && currLocal !== '') {
                  label = currLocal
                }
              }

              let key = el.name
              if (el.fieldType === 'object') {
                key = `${key}.presentation`
              }

              const field = { key, label, sortable: el.sortable, width: el.width, styles: el.styles, sortBy: el.sortBy }

              field.formatter = (value) => {
                if (el.dataType !== 'date' && el.format) {
                  return !value ? '-' : eval(el.format) || '-'
                } else if (el.dataType == 'date') {
                  return !value ? '-' : moment(value).format(el.format)
                } else if (el.dataType == 'boolean') {
                  return value === true ? this.$tc('common.yes') : this.$tc('common.no')
                } else if (el.dataType == 'enum') {
                  return !value ? '-' : this.$enums(el.ref, value)
                } else return value
              }

              return field
            })

            if (fields.length > 0) {
              fields[0].first = true
            }
            this.fields = fields
          }

          this.initQuickFilters()
        } else {
          this.$store
            .dispatch(`${this.valueType}/getSelectFields`)
            .then((result) => {
              if (result) {
                this.fields = result.map((el) => {
                  let field
                  if (this.fields[0].key === 'numberStr') {
                    field = { key: 'numberStr', label: this.$tc('table.numberStr'), sortable: el.sortable }
                  }
                  field = { key: el.key, label: this.$tc(el.label), sortable: el.sortable }
                  field.formatter = (value) => {
                    if (el.formatter?.dataType !== 'date' && el.formatter?.format) {
                      return !value ? '-' : eval(el.formatter?.format) || '-'
                    } else if (el.formatter?.dataType == 'date') {
                      return !value ? '-' : moment(value).format(el.formatter?.format)
                    } else if (el.formatter?.dataType == 'boolean') {
                      return value === true ? this.$tc('common.yes') : this.$tc('common.no')
                    } else if (el.formatter?.dataType == 'enum') {
                      return !value ? '-' : this.$enums(el.formatter?.ref, value)
                    } else return value ? value : '-'
                  }
                  return field
                })
              }
            })
            .catch((error) => {
              console.error(error)
            })

          if (this.advancedFilter) {
            this.$store
              .dispatch(`${this.valueType}/getAdvancedFilterFields`, { ...this.advancedFilterStatic })
              .then((result) => {
                if (result) {
                  this.advancedFilterFields = result.map((el) => {
                    const field = { key: el.key, label: this.$tc(el.label), type: el.type, parent: el.parent }
                    field.formatter = (value) => {
                      if (el.formatter?.dataType !== 'date' && el.formatter?.format) {
                        return !value ? '-' : eval(el.formatter?.format) || '-'
                      } else if (el.formatter?.dataType == 'date') {
                        return !value ? '-' : moment(value).format(el.formatter?.format)
                      } else if (el.formatter?.dataType == 'boolean') {
                        return value === true ? this.$tc('common.yes') : this.$tc('common.no')
                      } else if (el.formatter?.dataType == 'enum') {
                        return !value ? '-' : this.$enums(el.formatter?.ref, value)
                      } else return value ? value : '-'
                    }
                    return field
                  })
                }
              })
              .catch((error) => {
                console.error(error)
              })
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    initQuickFilters() {
      const viewSettings = this.userViewSettings ? this.userViewSettings : this.viewSettings ? this.viewSettings : null

      if (viewSettings.filters && viewSettings.filters?.length > 0) {
        let quickFilters = _.cloneDeep(this.filters.quick)

        quickFilters = quickFilters.filter((el) => {
          const existFilter = viewSettings.filters.find((sFilter) => {
            return el.name === sFilter.name
          })

          return existFilter !== undefined
        })

        for (const filter of viewSettings.filters) {
          const existFilterIdx = quickFilters.findIndex((el) => {
            return el.name === filter.name
          })

          if (existFilterIdx === -1) {
            const newFilter = _.cloneDeep(filter)

            if (newFilter.dataType === 'enum' && newFilter.ref) {
              const enumValues = this.$enums(newFilter.ref)

              if (enumValues) {
                newFilter.options = enumValues
              }
            }

            if (!newFilter.operator) {
              if (newFilter.dataType === 'string') {
                newFilter.operator = 'iLike'
              } else {
                newFilter.operator = 'eq'
              }
            }

            quickFilters.push(newFilter)
          } else {
            quickFilters[existFilterIdx].label = filter.label
            quickFilters[existFilterIdx].hidden = filter.hidden
          }
        }

        const hasValues = quickFilters.find((el) => {
          return el.use && el.hidden !== true
        })

        this.showQuickFilters = hasValues !== undefined

        this.filters.quick = quickFilters
      } else {
        this.filters.quick = []
      }
    },

    onChangeQuickFilter(filter) {
      if (filter) {
        const existFilterIdx = this.filters.quick.findIndex((el) => {
          return el.name === filter.name
        })

        if (existFilterIdx > -1) {
          this.filters.quick[existFilterIdx] = filter
        }

        this.updateList()
      }
    },

    async updateList() {
      const filterStr = {
        noCommit: true,
        params: {
          filter: { markedToDelete: false },
          pagination: { page: this.currentPage, limit: this.perPage },
          sort: { sortBy: this.sortBy, sortDesc: this.sortDesc },
        },
      }

      if (this.searchStr) {
        filterStr.params.filter.searchStr = this.searchStr
      }

      if (this.filter) {
        for (const filterProperty in this.filter) {
          filterStr.params.filter[filterProperty] = this.filter[filterProperty]
        }
      }

      const advancedFilters = this.makeAdvancedFilterObj()
      if (!advancedFilters) {
        filterStr.params.filter = { ...filterStr.params.filter, advanced: advancedFilters }
      }

      if (this.filters.quick.length > 0) {
        const quick = {}

        for (const filter of this.filters.quick) {
          if (filter.use !== true) {
            continue
          }

          let filterValue = ''

          if (filter.operator === 'iLike') {
            filterValue = `%${filter.value}%`
          } else if (filter.operator === 'filled' || filter.operator === 'notFilled') {
            filterValue = null
          } else if (filter.fieldType === 'object') {
            if (filter.operator === 'inList' || filter.operator === 'notInList') {
              filterValue = filter.value.map((el) => {
                return el.id
              })
            } else {
              filterValue = filter.value ? filter.value.id : filter.value
            }
          } else {
            filterValue = filter.value
          }

          if (filter.operator) {
            if (filter.operator === 'filled') {
              quick[filter.fieldPath] = { ['ne']: filterValue }
            } else if (filter.operator === 'notFilled') {
              quick[filter.fieldPath] = { ['eq']: filterValue }
            } else {
              quick[filter.fieldPath] = { [filter.operator]: filterValue }
            }
          } else {
            quick[filter.fieldPath] = filterValue
          }
        }

        filterStr.params.filter.quick = quick
      }

      let list = []
      let page = this.currentPage
      let total = 0

      await this.$store
        .dispatch(`${this.valueType}/findAll`, filterStr)
        .then((response) => {
          if (response.status === 200) {
            const result = response.data

            if (result.rows) {
              list = result.rows
              total = result.count
            } else {
              list = response.data
              total = response.data.length
            }

            if (total > 0) {
              const pages = Math.ceil(total / this.perPage)
              if (pages < page) {
                page = pages
              }
            } else {
              page = 1
            }
          } else {
            page = 1
          }
        })
        .catch((error) => {
          console.error(error)
          page = 1
        })

      this.items = list
      this.totalRows = total
      this.currentPage = page
    },

    async onSortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc

      this.updateList()
    },

    onChangeFilter() {
      this.updateList()
    },

    clearFilter() {
      this.filter = ''
      this.updateList()
    },

    onChangePage(page) {
      this.currentPage = page
      this.updateList()
    },

    onRowSelected(items) {
      if (items.length > 0) {
        this.$emit('value-selected', items[0])
      }
    },

    updateAdvancedFilter(name, val) {
      this.advancedFilters[name] = val
      this.updateList()
    },

    handleCancel() {
      this.$emit('value-selected', undefined)
    },

    handelClose() {
      this.$emit('value-selected', undefined)
    },

    async addDetailView() {
      if (this.advancedFilter) {
        const objectData = this.makeObjectData()

        const response = await this.$store.dispatch(`${this.valueType}/create`, objectData)

        if (response?.data) {
          this.onRowSelected([response.data])
        }
      } else {
        const viewId = uuid.v4()
        this.$store.dispatch(`${this.valueType}/addNew`, viewId)
        this.$router.push({ name: this.detailView, params: { id: viewId } })
      }
    },
    makeObjectData() {
      const objectData = { ...this.advancedFilterStatic }

      this.advancedFilterFields.map((field) => {
        const value = this.advancedFilters[field.key]
        if (value) {
          if (field.parent) {
            if (!objectData[field.parent]) {
              objectData[field.parent] = {}
            }
            objectData[field.parent][field.key] = value
          } else {
            objectData[field.key] = value
          }
        }
      })

      return objectData
    },
    makeAdvancedFilterObj() {
      const objectData = { ...this.advancedFilterStatic }

      this.advancedFilterFields.map((field) => {
        const value = this.advancedFilters[field.key]
        if (value) {
          if (field.parent) {
            if (!objectData[field.parent]) {
              objectData[field.parent] = { iLike: '' }
            }
            objectData[field.parent].iLike += `%${field.key}%:%${value}%`
          } else {
            objectData[field.key] = { iLike: `%${value}%` }
          }
        }
      })

      return objectData
    },
  },
}
</script>

<style></style>
