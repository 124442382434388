import store from '@/store'

export default function resetStates() {
  const modules = store._modulesNamespaceMap

  for (const moduleName in modules) {
    const moduleDefinition = modules[moduleName]

    const actions = moduleDefinition._rawModule.actions

    // If the action is defined on the module...
    if (actions && actions['resetState']) {
      // Dispatch the action if the module is namespaced. Otherwise,
      // set a flag to dispatch the action globally at the end.
      store.dispatch(`${moduleName}resetState`)
    }
  }
}
