import moment from 'moment'
import _ from 'lodash'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, changeDeletionMark, addNewItem, deleteItem, resetState } from '../service/actions'

import BasicObject from '@/dto/EmailAccount.json'
const apiUrl = 'email_accounts'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = { ...commonMutations }

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, itemHundler)
  },

  async getSignature(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, 'email_account_signature', payload)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  addObjectUser(context: ActionContext<IState, any>, payload: any) {
    const userId = payload.userId
    const viewId = payload.viewId

    const existView = state.objectViews.find((el: any) => el.viewId === viewId)
    if (existView) {
      existView.object.users.push({
        userId,
      })
    }
  },

  removeObjectUser(context: ActionContext<IState, any>, payload: any) {
    const userId = payload.userId
    const viewId = payload.viewId

    const existView = state.objectViews.find((el: any) => el.viewId === viewId)
    if (existView) {
      const removeIndex = existView.object.users.findIndex((item: any) => item.userId === userId)
      existView.object.users.splice(removeIndex, 1)
    }
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload, beforeAddNew)
  },

  async getSelectFields() {
    return [{ key: 'name', label: 'table.name', sortable: true }]
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

function itemHundler(itemData: any) {
  itemData.users = []
  itemData.usersCurrPage = 1
}

function beforeAddNew(itemData: any) {
  itemData.users = []
  itemData.usersCurrPage = 1
}

export const getters = { ...commonGetters }
