import customerRequestsActions from './customerRequests-actions'

export default {
  executeConditionCode(str, args) {
    const conditionFunction = new Function('args', str)
    const conditionRes = conditionFunction(args)

    return conditionRes
  },

  async executeAsyncConditionCode(str, args, $store) {
    const objectActions = {
      customerRequestsActions,
    }
    const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor
    const asyncFn = new AsyncFunction('args, $store, objectActions', str)
    const result = await asyncFn(args, $store, objectActions)

    return result
  },

  async executeAction($store, payload) {
    const data = { ...payload }
    const isManual = Boolean(data.isManual && !data.currentUser?.externalUser && data.currentUser?.fullRights)
    const users = await $store.dispatch('users/findAll', { noCommit: true }).then((res) => res.data)
    const statuses = await this.getOwnerObjStatuses($store, data.ownerType)
    const ownerObj = await this.getOwnerObj($store, data)
    const bpExemplar = await this.getBPExemplar($store, data.bpExemplarId || null)

    let actionResponse = null
    if (!isManual) {
      actionResponse = await this.executeAsyncConditionCode(
        data.action.script,
        {
          [data.ownerType]: ownerObj,
          executors: users,
          statuses,
        },
        $store
      )

      if (data.action?.allowChangeExecutor && data.customExecutor) {
        actionResponse.executorId = data.customExecutor?.id
      }

      data.actionResponse = actionResponse
    }

    const currentStageHistory = $store.state.bpHistories.histories.find((history) => {
      const stage = history.stage
      if (
        history.exemplarId === bpExemplar?.id
          ? bpExemplar?.id
          : ownerObj.bpExemplar.id && stage.id === bpExemplar?.stage.id
          ? bpExemplar?.stage.id
          : ownerObj.bpExemplar.stage.id && !history.finishedAt
      )
        return true
    })
    data.bpHistoryId = currentStageHistory.id

    let nextStage = null
    if ((isManual || data.action?.allowChangeStage) && data.customStageId) {
      nextStage = this.findStageById(data.customStageId, bpExemplar, ownerObj)
    } else {
      const nextStageId = await this.findNextStageId(actionResponse, ownerObj, $store, bpExemplar)
      nextStage = this.findStageById(nextStageId, bpExemplar, ownerObj)
    }

    if (isManual || data.action?.allowChangeExecutor) {
      nextStage = { ...nextStage, valueType: 'user', value: data.customExecutor.id }
    }
    data.nextStage = nextStage

    const res = await $store.dispatch('bpExemplars/executeAction', data)

    if (res.status !== 200) {
      return 'error'
    }

    if (!nextStage) {
      return 'not_found_next_stage'
    }

    return 'success'
  },

  async getBPExemplar($store, exemplarId) {
    const bpExemplar = await $store.dispatch('bpExemplars/findByPk', { noCommit: true, params: { id: exemplarId } })
    return bpExemplar.data
  },

  async getStoreName($store, ownerType) {
    let storeName = ''
    await $store
      .dispatch('appObjects/findAll', {
        noCommit: true,
        params: {
          filter: {
            singularName: ownerType,
          },
        },
      })
      .then((res) => (res.data.length > 0 ? (storeName = res.data[0].name) : (storeName = '')))

    return storeName
  },

  findStageById(id, bpExemplar, ownerObj) {
    let stage
    if (bpExemplar) {
      stage = bpExemplar.definition.stages.find((e) => e.uuid === id)
    } else {
      stage = ownerObj.definition.stages.array.find((e) => e.uuid === id)
    }
    return stage
  },

  async findNextStageId(actionResponse, ownerObj, $store, bpExemplar) {
    let nextStageId = bpExemplar ? bpExemplar.stage?.nextStage : ownerObj.stage?.nextStage
    const nextStage = this.findStageById(nextStageId, bpExemplar, ownerObj)

    if (nextStage?.isCondition) {
      const args = {
        customerRequest: { ...ownerObj, ...actionResponse },
      }

      for (const cond of nextStage.conditions) {
        const res = await this.executeAsyncConditionCode(cond.conditionCode, args, $store)
        if (res === true) {
          nextStageId = cond.nextStage
          break
        }
      }

      //   nextStage.conditions.every(async (cond) => {
      //     const res = await this.executeAsyncConditionCode(cond.conditionCode, args, $store)
      //     if (res) {
      //       nextStageId = cond.nextStage
      //       return false
      //     }
      //     return true
      //   })
    }

    if (nextStageId === nextStage.uuid && nextStage?.isCondition) {
      return null
    }
    return nextStageId
  },

  async getOwnerObj($store, { ownerType, ownerId }) {
    const storeName = await this.getStoreName($store, ownerType)
    if (!storeName) return null

    const response = await $store.dispatch(`${storeName}/findByPk`, { noCommit: true, params: { id: ownerId } })

    return response.data || null
  },

  async getOwnerObjStatuses($store, ownerType) {
    const storeName = await this.getStoreName($store, ownerType)
    if (!storeName) return null

    const response = await $store.dispatch(`${storeName}/findAll`, { noCommit: true })
    return response.data || null
  },

  async updateHistory($store, id) {
    const history = {
      id,
      finishedAt: new Date(),
    }

    $store.dispatch('bpHistories/updateHistory', history).catch((err) => console.error(err))
  },

  // async executeActionOld($store, payload) {
  //   const users = await $store.dispatch('users/findAll', { noCommit: true }).then((res) => res.data)
  //   const statuses = await this.getOwnerObjStatuses($store, payload.ownerType)
  //   const ownerObj = await this.getOwnerObj($store, payload)
  //   const currentUser = payload.currentUser
  //   const myTask = ownerObj.tasks.find((task) => task.executorId === currentUser.id)
  //   const isManual = Boolean(payload.isManual && !currentUser?.externalUser && currentUser?.fullRights)
  //   const storeName = await this.getStoreName($store, payload.ownerType)
  //   let actionResponse = null

  //   if (isManual) {
  //     // Cancel all tasks of current stage.
  //     await Promise.all(
  //       ownerObj.tasks.map(async (task) => {
  //         return await this.executeTask($store, { id: task.id, executionResult: 'canceled' })
  //       })
  //     )
  //   } else {
  //     if (myTask?.id) {
  //       await this.executeTask($store, { id: myTask.id, executionResult: payload.executionResult || '' })
  //     }

  //     // if there isn't my task or the task isn't connected bp or there are other started tasks, then can't either execute action or go to next stage.
  //     if (!isManual && (!myTask?.id || !myTask?.bpDefinition || ownerObj.tasks?.length > 1)) return

  //     // Execute action script.
  //     actionResponse = await this.executeAsyncConditionCode(
  //       payload.action.script,
  //       {
  //         [payload.ownerType]: ownerObj,
  //         executors: users,
  //         statuses,
  //       },
  //       $store
  //     )

  //     if (payload.action?.allowChangeExecutor && payload.customExecutor) {
  //       actionResponse.executorId = payload.customExecutor?.id
  //     }

  //     // Update owner object.
  //     await $store.dispatch(`${storeName}/update`, {
  //       id: ownerObj.id,
  //       updateData: actionResponse,
  //     })
  //   }

  //   // Finish current stage.
  //   const currentStageHistory = $store.state.bpHistories.histories.find((history) => {
  //     const stage = history.stage
  //     if (history.exemplarId === ownerObj.bpExemplar.id && stage.id === ownerObj.bpExemplar.stage.id && !history.finishedAt) return true
  //   })

  //   await this.updateHistory($store, currentStageHistory.id)

  //   let nextStage = null
  //   if ((isManual || payload.action?.allowChangeStage) && payload.customStageId) {
  //     nextStage = this.findStageById(payload.customStageId, ownerObj)
  //   } else {
  //     const nextStageId = await this.findNextStageId(actionResponse, ownerObj, $store)
  //     nextStage = this.findStageById(nextStageId, ownerObj)
  //   }

  //   if (isManual || payload.action?.allowChangeExecutor) {
  //     nextStage = { ...nextStage, valueType: 'user', value: payload.customExecutor.id }
  //   }

  //   if (nextStage) {
  //     await this.updateBpExemplarStage($store, nextStage, ownerObj, storeName, payload.ownerType)
  //     // Add history
  //     await this.addHistory($store, ownerObj, nextStage)
  //   } else {
  //     return 'not_found_next_stage'
  //   }
  // },
  // async addHistory($store, ownerObj, stage) {
  //   let executorId = null
  //   if (stage.valueType === 'user') {
  //     executorId = stage.value
  //   }

  //   const history = {
  //     stage: stage,
  //     exemplarId: ownerObj.bpExemplar.id,
  //     authorId: executorId,
  //   }

  //   await $store.dispatch('bpHistories/addHistory', history).catch((err) => console.error(err))
  // },
  // async updateBpExemplarStage($store, nextStage, ownerObj, storeName, ownerType) {
  //   await $store.dispatch('bpExemplars/updateItem', {
  //     id: ownerObj.bpExemplar.id,
  //     stage: nextStage,
  //   })

  //   if (nextStage?.isFinal) {
  //     return
  //   }

  //   const executorRoleId = nextStage.valueType === 'executor_role' ? nextStage.value : null
  //   let executorId = null
  //   if (nextStage.valueType === 'user') {
  //     executorId = nextStage.value
  //   }

  //   const newTaskParams = {
  //     ownerType: ownerType,
  //     ownerId: ownerObj.id,
  //     bpDefinition: ownerObj.bpExemplar.definitionId,
  //     customerId: ownerObj.customerId,
  //     nextStage: nextStage.text,
  //     lang: nextStage.lang?.text ? { name: nextStage.lang?.text } : null,
  //     nextStageId: nextStage.id,
  //     nextExecutorId: executorId,
  //     nextExecutorRoleId: executorRoleId,
  //     started: true,
  //   }

  //   await $store.dispatch('tasks/createTaskBasingOnManualRedirection', newTaskParams)

  //   //need to think how to redesign this
  //   await $store.dispatch(`${storeName}/update`, {
  //     id: ownerObj.id,
  //     updateData: {
  //       executorId: executorId,
  //     },
  //   })
  // },
  // async executeTask($store, payload) {
  //   const taskResult = await $store.dispatch('tasks/executeTask', payload)
  //   return taskResult
  // },
}
