import VueI18n from 'vue-i18n'
import { IVueI18n } from 'vue-i18n'
import { messages, dateTimeFormats } from './utils'

export function getLocaleInstance(locale: string): IVueI18n {
  const i18n = new VueI18n({
    locale,
    fallbackLocale: 'pl',
    messages,
    dateTimeFormats,
  })

  return i18n
}
