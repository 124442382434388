export default {
  route: {
    main: 'Haupt',
    dashboard: 'Inhaltsverzeichnis',
    sales: 'Verkauf',
    tasks: 'Aufgaben',
    salesOrders: 'Bestellungen',
    products: 'Produkte',
    counterparties: 'Kunden',
    reclamations: 'Reklamationen',
    reclCatalogs: 'Sprachen',
    reclSubjects: 'Bestellte Produkte',
    statusGroups: 'Gruppenstatus',
    reclStates: 'Auftragsstatus',
    reclTypes: 'Auftragstypen',
    reclExecutions: 'Realisierungen',
    reclDecisions: 'Entscheidungen',
    settings: 'Einstellungen',
    firms: 'Firmen',
    salesPoints: 'Verkaufsstellen',
    users: 'Nutzer',
    usersList: 'Nutzerliste',
    accessGroups: 'Berechtigungsgruppen',
    otherSettings: 'Andere Einstellungen',
    administration: 'Administration',
    emailTemplates: 'E-Mail-Vorlagen',
    numerators: 'Dokumentennummer',
    executors: 'Ausführende',
    executorRoles: 'Die Rollen der Ausführenden',
    exchangeObjects: 'Objects to exchange',
    taskTemplates: 'Aufgabenvorlagen',
    customerRequests: 'Customer requests',
    crStatuses: 'Customer request statuses',
    mailbox: 'Emails',
    salesOrder: 'Zamówienie klienta',
    customerRequest: 'Zapytanie ofertowe',
    newEmail: 'Nowy e-mail',
    emailDetails: 'E-mail',
    task: 'Zadanie',
    product: 'Dane pozycji',
    customer: 'Dane klienta',
    accessGroup: 'Grupa uprawnień',
    user: 'Użytkownik',
    executor: 'Wykonawca zadań',
    executorRole: 'Rola wykonawcy',
    taskTemplate: 'Szablon zadań',
    reclamation: 'Reclamation',
    reclamationStatistic: 'Reclamation statistic',
    emailAccounts: 'E-mail accounts',
    emailAccount: 'E-mail account',
  },
  navbar: {
    logOut: 'Ausloggen',
    clearAll: 'Alles Löschen',
    profile: 'Mein Profil',
    newNotes: 'Neue Nachrichten',
    checkAll: 'Prüfe alle',
    hello: 'Hallo!',
  },
  valueTypes: {
    number: 'Number',
    date: 'Data',
    string: 'Tekst',
    boolean: 'Logiczne',
    ref: 'Odnośnik',
  },
  login: {
    title: 'Login',
    username: 'Benutzer',
    usernamePh: 'E-Mail',
    invalidLogin: 'Falsche Benutzer-E-Mail oder falsches Passwort',
    unexpectedError: 'Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es erneut',
    password: 'Passwort',
    passwordPh: 'Passwort prüfen',
    fogotPassword: 'Passwort vergessen?',
    logIn: 'Login',
    noHaveAccount: 'Du hast kein Konto?',
    register: 'Registrieren',
  },
  register: {
    title: 'Registrierung von Benutzern',
    titleDescription: 'Sie haben noch kein Konto? Registrieren sich sich, es dauert weniger als eine Minute',
    firstLastName: 'Vorname und Nachname',
    enterDetailsPh: 'Geben Sie Ihre Daten ein',
    email: 'E-Mail',
    emailPh: 'Geben Sie Ihre E-Mail ein',
    terms: 'Nutzungsbedingungen des Portals',
    register: 'Registrieren Sie sich',
    haveAccount: 'Sie haben schon ein Konto?',
    confirm: 'Ihr Konto wurde erfolgreich registriert',
    sendTo: 'E-Mail wurde versendet an',
    emailCheck: 'Prüfen Sie Ihre E-Mails und klicken Sie auf den beigefügten Link, um Ihr Passwort zurückzusetzen.',
    backHome: 'Zurück zur Haupsteite',
  },
  customerRequest: {
    title: 'Zapytanie oferty',
    new: 'Nowe zapytanie oferty',
    newVersion: 'Nowa wersja zapytania oferty',
    edit: 'Edycja zapytania oferty nr ',
    presentation: 'Zapytanie oferty nr {0} od {1}',
    createNew: 'Utwórz zapytanie',
    editStatus: 'Zmiana statusu',
    addComment: 'Nowy kommentarz',
    editResult: 'Edycja wyniku',
    msg: {
      selectItemFirst: 'Wybierz najpierw zapytanie oferty!',
      return: 'Zapytanie oferty zostanie przywrócone!',
      delete: 'Zapytanie oferty zostanie zaznaczone do usunięcia!',
      orderAvailable: 'Zapytanie oferty z taką referencja już istnieje: {0}',
      emptyCustomer: 'Niewypełniony klient!',
      noWrite: 'Zapytanie oferty musi być zapisane!',
    },
  },
  enums: {
    IMPORTANCES: {
      NOT_SET: 'Nicht wichtig',
      HIGHT: 'Hoch',
      NORMAL: 'Normal',
      LOW: 'Niedrig',
    },
    BOM_STATUSES: {
      active: 'aktiv',
      draft: 'entwurf',
      canceled: 'abgebrochen',
    },
  },
  order: {
    title: 'Kundenbestellungen',
    objectTitle: 'Bestellung',
    new: 'Neuer Auftrag',
    presentation: 'Auftrag Nr. {0} von {1}',
    createTask: 'Aufgabe erstellen',
    msg: {
      selectOrderFirst: 'Wähle zuerst einen Auftrag!',
      return: 'Ihre Bestellung wird wieder aufgenommen!',
      delete: 'Bestellung wird für die Löschung markiert!',
      orderAvailable: 'Eine Bestellung mit deieser Referenz existiert bereits {0}',
      confirmRemoveProduct: 'Sicher das Sie dieses Produkt entfernen möchten?',
      emptyCustomer: 'Kunde nicht ausgefüllt!',
      writeOrder: 'Die Bestellung muss gespeichert werden!',
    },
    status: {
      OFERTA: 'Angebot',
      NEW: 'Neuer Auftrag',
      CONFIRMED: 'Bestätigt',
      READY_FOR_PRODUCTION: 'Bereit zum produzieren',
      CANCELED: 'Annuliert',
      CLOSED: 'Geschlossen',
    },
    deliveryMethods: {
      STANDARD: 'Standard',
      UNTIL_CERTAIN_DATE: 'Bis zu einem bestimmten Datum',
      ON_CERTAIN_DATE: 'Für ein bestimmtes Datum',
      VIP: 'VIP',
    },
    packageMaterial: {
      Karton: 'Karton',
      Folia: 'Folie',
    },
    enterReference: 'Refernez eingeben',
    enterProject: 'Projektname eingeben',
    enterDeliveryMethod: 'Wähle die Art der Zustellung',
    enterMaterial: 'Wähle das Material',
    shipmentDays: 'Versanddatum: ',
    roadDays: 'Tage unterwegs: ',
    deliveryDays: 'Liefertage: ',
    byOrderPeriod: 'Je nach Umfang der Bestellung',
    priceDetails: 'Preisdetails',
  },
  task: {
    executionReceive: 'Zur Ausführung akzeptieren',
    showExecuted: 'Ausgeführte anzeigen',
    showMyne: 'Zeige meine',
    description: 'Beschreibung der Aufgabe',
    executionResult: 'Ergebnis der Aufgabe',
    newTask: 'Neue Aufgabe',
  },
  commands: {
    accept: 'Akzeptieren',
    search: 'Suche',
    add: 'Hinzufügen',
    clear: 'Löschen',
    cancel: 'Annulieren',
    ok: 'OK',
    writeAndClose: 'Speichern und schließen',
    write: 'Speichern',
    close: 'Schließen',
    autofill: 'Automatisch ausfüllen',
    addProduct: 'Produkt hinzufügen',
    addExtraProduct: 'Zusätzliches Produkt hinzufügen',
    addFile: 'Anhang hinzufügen',
    addToCalendar: 'Zum Kalender hinzufügen',
    eventsList: 'Liste der Veranstaltungen',
    select: 'Auswählen',
    configProduct: 'Produkt konfigurieren',
    confirm: 'Bestätigen',
    confirmSelected: 'Auswahl bestätigen',
    confirmPrices: 'Preis bestätigen',
    calculate: 'Berechnen',
    execute: 'Ausführen',
    createOrder: 'Bestellung erstellen',
    runAndClose: 'Aktivieren und schließen',
  },
  common: {
    search: 'Suche...',
    filter: 'Filter',
    modalTitle: 'Achtung!',
    emptyField: '<nicht ausgefüllt>',
    mainData: 'Basisdaten',
    products: 'Produkte',
    files: 'Dateien',
    notEmptyField: 'Das Feld muss ausgefüllt sein',
    selectDate: 'Datum auswählen',
    enterComment: 'Bemerkungen eingeben',
    enterQuantity: 'Anzahl eingeben',
    total: 'Gesamt',
    selectFileTitle: 'Datei auswählen!',
    selectProductTitle: 'Produkt auswählen!',
    noSelectedFiles: 'Keine Datei ausgewählt',
    searchCustomer: 'Kunden suchen',
    double: 'Doppelte Bestellung',
    incorrectData: 'Falsche Daten wurden in das Feld eingegeben!',
    from: 'von',
    to: 'bis',
    noFound: '<nicht gefunden>',
    selected: 'Ausgewählte',
    addinDimensions: 'Zusätzliche Maße',
    numberOfPieces: 'Anzahl in Stück',
    lengthWidth: 'Länge/Breite',
    height: 'Höhe',
    recalculation: 'Umrechnung',
    active: 'Aktiv',
    deactive: 'Nicht aktiv',
    productData: 'Produktdetails',
    dateAdded: 'Eingabedatum',
    picturePath: 'Pfad zum Bild',
    enterPictureName: 'Bildnamen eingeben',
    describeProduct: 'Produkt beschreiben...',
    customerData: 'Kundendaten',
    erp: 'ERP',
    executorData: 'Daten vom Ausführenden',
    executorRoleData: 'Aufgabe der Ausührenden',
    emptyExecutorList: 'Liste der Ausführenden ist leer...',
    days: 'Tage',
    hours: 'Stunden',
    minutes: 'Minuten',
    executionToday: 'Ausführung heute',
    taskTemplatesData: 'Daten der Aufgabenvorlage',
    fillByTemplate: '-- Von der Vorlage ausfüllen --',
    enterName: 'Namen eingeben',
    selectImportance: 'Priorität auswählen',
    selectExecutorRole: 'Rolle des Ausführenden auswählen',
    selectExecutor: 'Ausführenden auswählen',
    history: 'Verlauf',
    yes: 'Yes',
    no: 'No',
  },
  weekDays: {
    Mo: 'Mo',
    Tu: 'Di',
    We: 'Mi',
    Th: 'Do',
    Fr: 'Fr',
    Sa: 'Sa',
    Su: 'So',
  },
  table: {
    sort: 'LfdNr.',
    sortNumber: 'Anzahl',
    code: 'Kode',
    number: 'Nummer',
    name: 'Name',
    article: 'Artikel',
    type: 'Typ',
    customer: 'Kunde',
    reference: 'Referenz',
    createdAt: 'Erstellt',
    deliveryDate: 'Lieferdatum',
    shipmentDate: 'Versanddatum',
    status: 'Status',
    author: 'Autor',
    price: 'Preis',
    sum: 'Summe',
    sumWithoutVAT: 'Summe Netto exkl. MwSt.',
    sumVAT: 'Summe MwSt.',
    sumWithVAT: 'Summe Brutto inkl. MwSt.',
    currency: 'Währung',
    note: 'Notizen',
    paymentTerms: 'Zahlungsbedingungen',
    project: 'Projekt',
    deliveryMethod: 'Versandart',
    packageMaterial: 'Art der Verpackung',
    comment: 'Anmerkungen!',
    product: 'Produkt',
    description: 'Beschreibung',
    jOM: 'Einheit',
    quantity: 'Anzahl',
    discountPercent: '% Rabatt',
    discountSum: 'Summe Rabatt',
    fileSize: 'Dateigröße, kB',
    country: 'Land',
    email: 'E-Mail',
    address: 'Anschrift',
    phone: 'Telefon',
    abbreviation: 'Kundenkürzel',
    actions: 'Aktionen',
    priceCode: 'Preiscode',
    executor: 'Ausführende',
    executionPeriod: 'Produktionsdatum',
    changeDate: 'Datum der Änderung',
    user: 'Nutzer',
    nip: 'Umsatzsteuernummer',
    region: 'Region',
    pricesType: 'Preistyp',
    customersGroup: 'Kundengruppe',
    language: 'Sprache',
    executorName: 'Name vom Ausführenden',
    importance: 'Relevanz',
    executorRole: 'Rolle des Ausführenden',
    contents: 'Inhalt',
    template: 'Vorlage',
    base: 'Grundlage',
    task: 'Aufgabe',
    category: 'Kategorie',
    priority: 'Priorität',
    postDate: 'Versanddatum',
    topic: 'Thema',
    objectType: 'Object type',
    objectDescription: 'Object description',
    objectId: 'Object ID',
    changeType: 'Change type',
    sent: 'Sent',
    constructor: 'Constructor',
    constr: 'Konstruktor',
    manager: 'Manager',
    commission: 'Commission, %',
    version: 'Version',
    orderNumber: 'Order number',
    isActive: 'Is active',
    title: 'Titel',
    notes: 'Anmerkungen',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
  },
  configurator: {
    selectedParameters: 'Ausgewählte Parameter',
    byNumber: 'nach nr...',
    byName: 'nach Name/ Beschreibung ...',
  },
  settings: {
    title: 'Einstellungen',
    lsbThemeDefault: 'Standard',
    lsbThemeLight: 'Hell',
    lsbThemeDark: 'Dunkel',
    lsbTypeFixed: 'Voll',
    lsbTypeCondensed: 'Ausgerollt',
    lsbTypeScrollable: 'Scrollbar',
  },
  editTypes: {
    created: 'Created',
    edited: 'Edited',
    markedToDeleteRestore: 'Marked to delete / Restore',
    deleted: 'Deleted',
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
  },
  documentation: {},
  permission: {},
  guide: {},
  components: {
    historyOfChanges: 'Auflistung der Änderungen.',
    relatedTasks: 'zugehörige Aufgaben.',
  },
  example: {},
  errorLog: {},
  excel: {},
  zip: {},
  pdf: {},
  theme: {
    leftSidebar: 'Linke Seitenleiste',
  },
}
