<template>
  <div>
    <div class="row">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('table.use')" label-for="field-use">
          <b-form-checkbox id="field-use" v-model="use" switch size="sm" class="mt-1"> </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('table.title')" label-for="item-title">
          <b-input-group>
            <b-form-input id="item-title" v-model.trim="label" type="text" size="sm" autofocus />
            <b-input-group-append>
              <Translation v-model="lang" input="label" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div class="row" v-if="operators.length > 0">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('table.operator')">
          <b-form-select :value="operator" :options="operators" size="sm" @change="(value) => onChangeOperator(value)"></b-form-select>
        </b-form-group>
      </div>
    </div>
    <div class="row" v-if="operator !== 'filled' && operator !== 'notFilled'">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('table.value')">
          <div v-if="filter.fieldType === 'object'">
            <f-select v-model="value" select-btn :value-type="filter.ref" :multiple="multiple"> </f-select>
          </div>
          <div v-else-if="filter.fieldType === 'enum'">
            <b-form-select v-model="value" :options="enumOptions" :multiple="multiple" size="sm"> </b-form-select>
          </div>
          <div v-else-if="filter.fieldType === 'boolean'">
            <b-form-select v-model="value" :options="booleanOptions" size="sm"> </b-form-select>
          </div>
          <div v-else>
            <b-form-input v-model="value" :type="filter.fieldType" size="sm" />
          </div>
        </b-form-group>
      </div>
    </div>
    <div class="row" v-if="ref">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('valueTypes.ref')">
          <b-form-input v-model="ref" type="text" size="sm" />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-form-group horizontal :label-cols="6" :label="$t('table.hidden')">
          <b-form-checkbox :checked="hidden" switch size="sm" class="mt-1" @change="(value) => (this.hidden = value)"> </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-form-group horizontal :label-cols="6" :label="$t('common.fillNew')">
          <b-form-checkbox :checked="fillNew" switch size="sm" class="mt-1" @change="(value) => (this.fillNew = value)"> </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import Translation from '@/components/common/translation.vue'

export default {
  name: 'FilterDetail',

  components: {
    Translation,
  },

  props: {
    filter: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      booleanOptions: [
        { value: true, text: this.$tc('common.yes') },
        { value: false, text: this.$tc('common.no') },
      ],
      enumOptions: [],
      operators: [],
      multiple: false,
      definedOperators: {
        object: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        enum: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        text: ['eq', 'ne', 'iLike', 'notILike', 'filled', 'notFilled'],
        number: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
        date: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
      },
    }
  },

  computed: {
    use: {
      get() {
        return this.filter.use
      },
      set(value) {
        this.onChangeProperty({ use: value })
      },
    },

    name: {
      get() {
        return this.filter.name
      },
      set(value) {
        this.onChangeProperty({ name: value })
      },
    },

    label: {
      get() {
        return this.filter.label
      },
      set(value) {
        this.onChangeProperty({ label: value })
      },
    },

    hidden: {
      get() {
        return this.filter.hidden
      },
      set(value) {
        this.onChangeProperty({ hidden: value })
      },
    },

    fillNew: {
      get() {
        return this.filter.fillNew
      },
      set(value) {
        this.onChangeProperty({ fillNew: value })
      },
    },

    operator: {
      get() {
        console.log("new operator: " , this.filter.operator)
        return this.filter.operator
      },
      set(value) {
        this.onChangeProperty({ operator: value })
      },
    },

    value: {
      get() {
        return this.filter.value
      },
      set(value) {
        this.onChangeProperty({ value: value })
      },
    },

    ref: {
      get() {
        return this.filter.ref
      },
      set(value) {
        this.onChangeProperty({ ref: value })
      },
    },

    lang: {
      get() {
        return this.filter.lang
      },
      set(value) {
        this.onChangeProperty({ lang: value })
      },
    },
  },

  watch: {
    filter() {
      this.initOperators()
      this.multiple = this.operator === 'inList' || this.operator === 'notInList'
    },
  },

  mounted() {
    this.initOperators()

    if (this.filter.fieldType === 'enum') {
      this.enumOptions = this.$enums(this.filter.ref)
    }

    this.multiple = this.operator === 'inList' || this.operator === 'notInList'
  },

  methods: {
    initOperators() {
      let operators = []

      if (this.filter.fieldType !== 'boolean') {
        operators = this.definedOperators[this.filter.fieldType]
      }

      this.operators = operators.map((el) => {
        return { value: el, text: this.$tc(`operators['${el}']`) }
      })
    },

    onChangeProperty(propValue) {
      const filter = { ...this.filter }

      Object.keys(propValue).forEach((key) => {
        filter[key] = propValue[key]
      })

      this.$emit('change', filter)
    },

    onChangeOperator(operator) {
      if (operator === 'inList' || operator === 'notInList') {
        this.onChangeProperty({ value: [], operator })
        this.multiple = true
      } else {
        this.onChangeProperty({ value: null, operator })
        this.multiple = false
      }
    },
  },
}
</script>

<style>
</style>