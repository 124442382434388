<template>
  <b-modal
    :visible="visible"
    size="lg"
    dialog-class="pl-0 pl-md-5"
    :title="`${$t('common.selectPossition')}`"
    :cancel-title="$t('commands.cancel')"
    no-close-on-backdrop
    @cancel="handleCancel"
    @close="handelClose"
  >
    <div class="row">
      <div class="col">
        <b-table
          no-border-collapse
          :items="items"
          :fields="fields"
          responsive="sm"
          selectable
          select-mode="single"
          no-local-sorting
          sticky-header="400px"
          small
          @row-selected="onRowSelected"
        >
        </b-table>
      </div>
    </div>

    <template v-slot:modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel"> {{ $t('commands.cancel') }} </b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      require: true,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [{ key: 'label', label: this.$t('table.name') }],
    }
  },

  methods: {
    onRowSelected(items) {
      if (items.length > 0) {
        this.$emit('selected', items[0])
      }
    },

    handleCancel() {
      this.$emit('selected', undefined)
    },

    handelClose() {
      this.$emit('selected', undefined)
    },
  },
}
</script>

<style></style>
