import moment from 'moment'
import _ from 'lodash'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, addNewItem, resetState, deleteItem, changeDeletionMark } from '../service/actions'

import BasicObject from '@/dto/ProductVariant.json'
const apiUrl = 'product_variants'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = { ...commonMutations }

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, itemHundler)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload)
  },

  async getSelectFields() {
    return [
      { key: 'name', label: 'table.name', sortable: true },
      { key: 'predefinedName', label: 'table.predefinedName', sortable: true },
      { key: 'variants', label: 'table.variants', sortable: true },
    ]
  },

  async getAdvancedFilterFields(context: ActionContext<IState, any>, payload: any) {
    const fields = [
      { key: 'name', label: 'table.name', type: 'text', parent: '' },
      { key: 'predefinedName', label: 'table.predefinedName', type: 'text', parent: '' },
    ]

    const commonParams = await getVariantDefinitions(context)
    commonParams.map((param: any) => {
      if (!fields.find((filed) => filed.key === param.name)) {
        fields.push({
          key: param.name,
          label: param.name,
          type: param.type,
          parent: 'variants',
        })
      }
    })

    const productId = payload?.productId || null
    if (productId) {
      const productParams = await getVariantDefinitions(context, productId)
      productParams.map((param: any) => {
        if (!fields.find((filed) => filed.key === param.name)) {
          fields.push({
            key: param.name,
            label: param.name,
            type: param.type,
            parent: 'variants',
          })
        }
      })
    }

    return fields
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

function itemHundler(itemData: any) {
  itemData.object.createdAt = moment(itemData.object.createdAt).format('DD.MM.YYYY HH:mm:ss')
}

const getVariantDefinitions = async (context: ActionContext<IState, any>, productId: string | null = null) => {
  const variantDefinitions = await context
    .dispatch(
      'productVariantDefinitions/findAll',
      {
        noCommit: true,
        params: {
          filter: { productId },
        },
      },
      { root: true }
    )
    .then((res: any) => res.data)

  const params: any = []
  variantDefinitions.map((variantDefinition: any) => {
    params.push(...variantDefinition.additionalParameters)
  })
  return params
}

export const getters = { ...commonGetters }
