import _ from 'lodash'
import { ActionContext, GetterTree } from 'vuex'

interface IShipsPlanning {
  diagramPeriod: Array<any>
}

const initState: IShipsPlanning = {
  diagramPeriod: [null, null],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setDiagramPeriod(state: IShipsPlanning, payload: Array<any>) {
    state.diagramPeriod = payload
  },

  resetState(state: IShipsPlanning) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  resetState(context: ActionContext<IShipsPlanning, any>) {
    context.commit('resetState')
  },
}
