<template>
  <b-modal
    id="filters-manager"
    size="xl"
    :title="`${$t('common.filtersManager')}`"
    :cancel-title="$t('commands.cancel')"
    cancel-variant="outline-secondary"
    v-model="showDlg"
    no-close-on-backdrop
    :ok-title="`${$t('commands.ok')}`"
    @ok="handleOk"
    @hide="handleClose"
  >
    <b-card>
      <div class="row">
        <div class="col pr-0">
          <b-card class="mb-0" no-body>
            <b-card-header class="py-0 px-2">
              <h5>{{ $t('common.objectFields') }}</h5>
            </b-card-header>
            <div class="list-conteiner mx-1 mt-2">
              <ObjectField
                :fields="objectFields"
                :currField="currObjectField"
                @active-field="(field) => (currObjectField = field)"
                @select-field="onSelectField"
              ></ObjectField>
            </div>
          </b-card>
        </div>
        <div class="col pr-0">
          <b-card class="mb-0" no-body>
            <b-card-header class="py-0 px-2">
              <h5>{{ $t('common.selectedFields') }}</h5>
            </b-card-header>
            <div class="list-conteiner mx-1 mt-2">
              <span v-if="filters?.length === 0" class="ml-2"> {{ $t('common.noFieldsAvailable') }}</span>
              <draggable group="columns" v-model="filters">
                <div v-for="(filter, idx) in filters" :key="idx">
                  <div class="row field-item" :class="{ 'item-active': currFilter === filter }" @click="currFilter = filter">
                    <div class="col d-flex">
                      <div class="w-100">
                        <a :id="'btn-' + filter.name" v-b-toggle="'field-' + filter.name" href="#" class="mr-2 float-left">
                          <i class="ri-arrow-up-s-line when-open my-auto icon-btn"></i>
                          <i class="ri-arrow-down-s-line when-closed my-auto icon-btn"></i>
                        </a>
                        <span>{{ filter.label }}</span>
                      </div>
                      <i class="ri-arrow-up-down-line mr-2"></i>
                      <a href="javascript:void(0);" @click="deleteFilter(idx)"><i class="ri-close-line text-danger float-right"></i></a>
                    </div>
                  </div>
                  <b-collapse :id="'field-' + filter.name" accordion="filters-accordion">
                    <b-card class="mb-1">
                      <filter-detail :filter="filter" @change="onChangeFilter"></filter-detail>
                    </b-card>
                  </b-collapse>
                </div>
              </draggable>
            </div>
          </b-card>
        </div>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import ObjectField from './object-field.vue'
import { defineFieldType, defineDataType } from '@/utils/app-service'
import Draggable from 'vuedraggable'
import FilterDetail from './filter-detail.vue'
import Languages from '@/dto/Languages.json'
import { getLocaleInstance } from '@/lang/local'

export default {
  name: 'FiltersManager',

  components: {
    ObjectField,
    Draggable,
    FilterDetail,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    objectId: {
      type: String,
      default: null,
    },
    currFilters: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filters: [],
      objectFields: [],
      currObjectField: null,
      currFilter: null,
    }
  },

  computed: {
    showDlg: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  mounted() {
    if (this.filters) {
      this.filters = cloneDeep(this.currFilters)
    }

    this.initObjectMeta()

    console.log("view settings props: value: " , this.value , " objectId: " , this.objectId , " currentFilters: " , this.currFilter)
  },

  methods: {
    async initObjectMeta() {
      this.objectFields = []

      if (this.objectId) {
        await this.$store.dispatch('appObjects/findByPk', { noCommit: true, params: { id: this.objectId } }).then(async (response) => {
          if (response.data) {
            await this.fillObjectFields(1, this.objectFields, response.data.model)
          }
        })
      }
    },

    async fillObjectFields(lvl, fields, model, parent = null) {
      model = Array.isArray(model) ? model : [model]

      for (const modelName of model) {
        await this.$store.dispatch('app/getObjectMeta', { params: { objectType: modelName, withTableParts: false } }).then((response) => {
          if (response.data) {
            const objectMeta = response.data

            for (const objectField of objectMeta) {
              const addField = fields.some((obj) => obj.name === objectField.name)
              if (addField) {
                continue
              }
              let currLvl = lvl

              if (objectField.type !== 'VIRTUAL') {
                const label = this.$t(`table.${objectField.name}`)

                const fieldPath = objectField.fieldPath ? objectField.fieldPath : objectField.name
                const fieldLabel = label.includes('table.') ? objectField.name : label

                let fieldType
                if (fieldPath !== objectField.name && objectField.ref && objectField.model) {
                  fieldType = 'object'
                } else {
                  fieldType = defineFieldType(objectField.type)
                }

                const field = {
                  name: objectField.name,
                  type: 'listField',
                  path: fieldPath,
                  label: fieldLabel,
                  fullName: parent ? `${parent.fullName}.${objectField.name}` : objectField.name,
                  fullPath: parent ? `${parent.fullName}.${fieldPath}` : fieldPath,
                  fieldTypePath: objectField.fieldTypePath ? objectField.fieldTypePath : null,
                  fullLabel: parent ? `${parent.fullLabel} [${fieldLabel}]` : fieldLabel,
                  fieldType,
                  multiType: objectField.multiType ? objectField.multiType : false,
                  dataType: defineDataType(objectField.type),
                  maxLength: objectField.maxLength,
                  allowNull: objectField.allowNull,
                  defaultValue: objectField.defaultValue,
                  ref: objectField.ref,
                  model: objectField.model,
                  sortBy: objectField.sortBy,
                  tableName: null,
                  childs: [],
                }

                if (fieldType === 'object' && objectField.name !== 'id' && lvl < 4) {
                  this.fillObjectFields(++currLvl, field.childs, objectField.model, field)
                }

                fields.push(field)
              }
            }
          }
        })
      }
    },

    onSelectField(field) {
      const existedField = this.filters.find((el) => el.fieldPath === field.fullPath)

      if (!existedField) {
        const newField = this.newFilter(field)

        this.filters.push(newField)

        this.currFilter = null
        if (this.filters?.length > 0) {
          this.currFilter = this.filters[0]
        }
      }
    },

    onChangeFilter(filter) {
      const index = this.filters.findIndex((el) => el.fieldPath === filter.fieldPath)

      if (index > -1) {
        this.filters[index] = filter
        this.currFilter = this.filters[index]
      }
    },

    newFilter(field) {
      return {
        use: true,
        name: field.fullName,
        fieldPath: field.fullPath,
        label: field.fullLabel,
        fieldType: field.fieldType,
        dataType: field.dataType,
        ref: field.ref,
        format: '',
        operator: null,
        value: null,
        hidden: false,
        fillNew: false,
        lang: this.getLang(field.name),
      }
    },

    getLang(name) {
      const lang = { label: {} }

      for (const language of Languages) {
        const locale = language.code
        const i18nLocal = getLocaleInstance(locale)
        const translate = i18nLocal.tc(`table.${name}`)
        if (!translate.includes('table.')) {
          lang.label[locale] = translate
        }
      }

      return lang
    },

    deleteFilter(idx) {
      this.filters.splice(idx, 1)

      this.currFilter = null
      if (this.filters?.length > 0) {
        this.currFilter = this.filters[0]
      }
    },

    async handleOk(event) {

      const newFilters = JSON.stringify(this.filters)

      this.$emit('edit-filters-end', newFilters)
    },

    async handleClose(event){

      console.log("closing in view...")

      this.$emit('edit-filters-closed')
    }
  },
}
</script>

<style lang="scss" scoped>
.list-conteiner {
  height: 45vh;
  overflow-y: auto;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
}

.field-item {
  padding: 0.3rem;
  border: dotted #adb5bd 1px;
  background-color: #fefefe;
  border-radius: 0.25rem;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 3px;
}

.item-active {
  background-color: #dee2e6;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>